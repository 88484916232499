<template>
  <div class="form-custom">
    <cube-form
      ref="form"
      class="form-wrapper"
      :model="model"
      :immediate-validate="false"
      :options="formOptions"
      :submit-always-validate="true"
      @validate="validateHandler"
      @submit.prevent="submitHandler"
    >
      <div class="form-content">
        <cube-form-group>
          <cube-form-item :field="fields[0]">
            <div
              class="field-wrapper"
              @click="selectCustomer"
            >
              <cube-input
                v-model="model.custCn"
                class="cube-inputs"
                :readonly="true"
                placeholder="请选择"
              />
              <i
                v-if="selectCustCn"
                class="icon-joyo arrows"
              >&#xe66c;</i>
            </div>
          </cube-form-item>
          <cube-form-item
            :field="fields[1]"
            @click.native="appointToast('联系人姓名')"
          />
          <cube-form-item :field="fields[2]" />
          <cube-form-item
            :field="fields[3]"
            @click.native="appointToast('手机号')"
          />
          <cube-form-item :field="fields[4]" />
          <cube-form-item :field="fields[5]" />
          <cube-form-item :field="fields[6]">
            <div class="field-wrapper">
              <!-- <cube-input
                v-model="model.birthday"
                :readonly="true"
                placeholder="请选择"
              /> -->
              <div
                class="cube-select"
                :class="changeColor(model.birthday)"
                @click="showDatePicker"
              >
                {{ model.birthday || "请选择" }}
              </div>
              <date-picker
                ref="datePicker"
                :min="[1900, 1, 1]"
                :max="[2050, 1, 1]"
                :needdefault="false"
                @select="datesSelectHandler"
              />
              <i class="icon-joyo arrows">&#xe66c;</i>
            </div>
          </cube-form-item>
          <cube-form-item
            :field="fields[7]"
            @click.native="appointToast('邮箱')"
          />
          <cube-form-item :field="fields[8]" />
          <cube-form-item :field="fields[9]" />
          <cube-form-item
            :field="fields[10]"
            @click.native="appointToast('固定电话')"
          />
          <cube-form-item :field="fields[11]">
            <div
              class="field-wrapper"
              @click="showAddressPicker"
            >
              <cube-input
                v-model="model.address"
                :readonly="true"
                placeholder="请选择"
              />
              <i class="icon-joyo arrows">&#xe66c;</i>
            </div>
          </cube-form-item>
          <cube-form-item :field="fields[12]" />
          <cube-form-item :field="fields[13]" />
          <cube-form-item :field="fields[14]" />
          <cube-form-item :field="fields[15]" />
          <cube-form-item
            :field="fields[16]"
            class="no-border"
          />
          <cube-form-item />
        </cube-form-group>
      </div>
      <div class="save-btn">
        <cube-button type="submit">
          保存
        </cube-button>
      </div>
    </cube-form>
    <ShowSearch
      v-if="showSearchCustomer"
      :placeholder="placeholder"
      :default-request-data="params"
      search-type="custCn"
      :default-func="defaultFunc"
      :func="func"
      @conceal="showSearchCustomer = false"
      @option="confirmCustomer"
    />
  </div>
</template>

<script>
import datePicker from '_c/date-picker';
import config from '../../lib/config';
import {
  getAllCustList,
  myCustWithBusOppoList
} from '../../api/global';
import { addContact, custContactsDetail } from '@/api/contact';
import ShowSearch from '_c/search/index';
import { mapGetters } from 'vuex';
import { provinceList, cityList, areaList } from '@/common/data/area';

const addressData = provinceList;
addressData.forEach(province => {
  province.children = cityList[province.value];
  province.children.forEach(city => {
    city.children = areaList[city.value];
  });
});

export default {
  name: 'AddeditContact',
  components: {
    ShowSearch,
    datePicker
  },
  data() {
    return {
      model: {
        id: 0,
        custId: '',
        custCn: '',
        name: '',
        position: '',
        mobile: '',
        decisionMaker: '',
        sex: '',
        birthday: '',
        email: '',
        wx: '',
        qq: '',
        tel: '',
        address: '',
        addrProvince: '',
        addrCity: '',
        addrArea: '',
        addrStreet: '',
        hobby: '',
        busCardAId: [],
        busCardAfileId: [],
        busCardBId: [],
        busCardBfileId: [],
        remark: '',
        contractRelationFlag: '' // 是否指定联系人
      },
      selectCustCn: true,
      fields: [
        // 0
        {
          type: 'input',
          modelKey: 'custCn',
          label: '选择客户',
          props: { placeholder: '请选择', disabled: this.selectCustCn },
          rules: { required: true }
        },
        // 1
        {
          type: 'input',
          modelKey: 'name',
          label: '联系人姓名',
          props: { placeholder: '请输入', clearable: true, disabled: false },
          rules: { required: true, max: 20 }
        },
        // 2
        {
          type: 'input',
          modelKey: 'position',
          label: '职位',
          props: { placeholder: '请输入', clearable: true },
          rules: { required: true, max: 10 }
        },
        // 3
        {
          type: 'input',
          modelKey: 'mobile',
          label: '手机号',
          props: { placeholder: '请输入', clearable: true, disabled: false },
          rules: {
            // required: true,
            // pattern: /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/
            pattern: /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][0-9]))[0-9]{8}$/
          }
        },
        // 4
        {
          type: 'select',
          modelKey: 'decisionMaker',
          label: '是否为决策人',
          props: { placeholder: '请选择', options: [] },
          rules: { required: false }
        },
        // 5
        {
          type: 'select',
          modelKey: 'sex',
          label: '性别',
          props: { placeholder: '请选择', options: [] },
          rules: { required: false }
        },
        // 6
        {
          type: 'input',
          modelKey: 'birthday',
          label: '生日',
          props: { placeholder: '请选择' },
          rules: { required: false }
        },
        // 7
        {
          type: 'input',
          modelKey: 'email',
          label: '邮箱',
          props: { placeholder: '请输入', clearable: true, disabled: false },
          rules: {
            required: false
            // pattern: /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]+$/
          }
        },
        // 8
        {
          type: 'input',
          modelKey: 'wx',
          label: '微信',
          props: { placeholder: '请输入', clearable: true },
          rules: { required: false, max: 20 }
        },
        // 9
        {
          type: 'input',
          modelKey: 'qq',
          label: 'QQ',
          props: { placeholder: '请输入', clearable: true },
          rules: { required: false, max: 20 }
        },
        // 10
        {
          type: 'input',
          modelKey: 'tel',
          label: '固定电话',
          props: { placeholder: '请输入', clearable: true, disabled: false },
          rules: { required: false, max: 20 }
        },
        // 11
        {
          type: 'input',
          modelKey: 'address',
          label: '联系人地址',
          props: { placeholder: '请选择', readonly: true },
          rules: { required: false }
        },
        // 12
        {
          type: 'textarea',
          modelKey: 'addrStreet',
          label: '详细地址',
          props: { placeholder: '最多输入50个汉字', max: 50, maxlength: 50},
          rules: { required: false }
        },
        // 13
        {
          type: 'textarea',
          modelKey: 'hobby',
          label: '爱好',
          props: { placeholder: '请输入', max: 50, maxlength: 50},
          rules: { required: false }
        },
        // 14
        {
          type: 'upload',
          modelKey: 'busCardAId',
          label: '名片正面',
          events: {
            'file-click': (...args) => {
              const imgs = args[0].response ? [args[0].response.data.record ? args[0].response.data.record.fileUrl : ''] : [args[0].fileUrl];
              this.$createImagePreview({ imgs }).show();
            }
          },
          props: {
            max: 1,
            action: {
              target: `${config.BASE_URL}/client/tencent/getFileAndFileIdFeign`,
              headers: { token: this.$store.state.token },
              data: {
                type: 99,
                time: 0
              }
            }
          },
          rules: { required: false },
          messages: {
            uploaded: '上传失败'
          }
        },
        // 15
        {
          type: 'upload',
          modelKey: 'busCardBId',
          label: '名片反面',
          events: {
            'file-click': (...args) => {
              const imgs = args[0].response ? [args[0].response.data.record.fileUrl] : [args[0].fileUrl];
              this.$createImagePreview({ imgs }).show();
            }
          },
          props: {
            max: 1,
            action: {
              target: `${config.BASE_URL}/client/tencent/getFileAndFileIdFeign`,
              headers: { token: this.$store.state.token },
              data: {
                type: 99,
                time: 0
              }
            }
          },
          rules: { required: false },
          messages: {
            uploaded: '上传失败'
          }
        },
        // 16
        {
          type: 'textarea',
          modelKey: 'remark',
          label: '备注',
          props: { placeholder: '请输入', max: 50, maxlength: 50 },
          rules: { required: false }
        }
      ],
      formOptions: {
        scrollToInvalidField: true
      },
      placeholder: '请输入客户名称',
      func: getAllCustList,
      defaultFunc: myCustWithBusOppoList,
      showSearchCustomer: false,
      params: {}
      // requestData: {
      //   custId: '',
      //   pageNum: 1,
      //   pageSize: 10
      // }
    };
  },
  computed: {
    ...mapGetters(['dictList'])
  },
  mounted() {
    this.syncModels();
    this.createAddressPicker();
    if (this.$route.query.contactId) {
      this.selectCustCn = false;
      this.toEdit(this.$route.query.contactId);
    }
    if (this.$route.query.from === 'qyWeChat') this.toQyWeChat();
  },
  methods: {
    changeColor(value) {
      if (value !== '') {
        return 'color-333';
      }
      return 'color-ccc';
    },
    searchCustName(item) {
      this.model.custId = item.id;
      this.model.custName = item.name;
      this.showSearchCustomer = false;
    },
    syncModels() {
      this.fields[5].props.options = this.dictList.sex;
      this.fields[4].props.options = this.dictList.decisionMaker;
      if (this.$route.query.custId && this.$route.query.custCn) {
        this.selectCustCn = false;
        this.model.custCn = this.$route.query.custCn;
        this.model.custId = this.$route.query.custId;
      }
    },
    selectCustomer() {
      if (this.selectCustCn) {
        this.showSearchCustomer = true;
      }
    },
    showDatePicker() {
      this.$refs.datePicker.show();
    },
    datesSelectHandler(selectedVal) {
      this.model.birthday = selectedVal;
    },
    confirmCustomer(data) {
      this.model.custCn = data.title;
      this.model.custId = data.id;
      this.showSearchCustomer = false;
    },
    // 效验报错提示
    validateHandler(result) {
      // if (!result.valid) {
      //   for (let i in result.validity) {
      //     if (result.validity[i].valid === false) {
      //       for (let j = 0 ; j < this.fields.length; j++) {
      //         let item = this.fields[j];
      //         if (item.modelKey === i) {
      //           this.$showToast(item.label + '为空，不能保存');
      //           return;
      //         }
      //       }
      //     }
      //   }
      // }
    },
    // 提交
    submitHandler() {
      if (this.model.mobile === '' && this.model.tel === '') {
        this.$showToast('手机号码、固定电话必填其一，请检查!');
        return;
      }
      this.model.busCardAfileId = this.model.busCardAId.length ? this.model.busCardAId.map(item => item.response ? item.response.data.record.fileId : item.fileId)[0] : 0;
      this.model.busCardBfileId = this.model.busCardBId.length ? this.model.busCardBId.map(item => item.response ? item.response.data.record.fileId : item.fileId)[0] : 0;
      addContact(this.model).then(res => {
        if (res.flag) {
          this.$showToast(res.errorMsg);
          this.$refs.form.reset();
          this.$router.go(-1);
        } else {
          this.$showToast(res.errorMsg);
        }
      });
    },
    createAddressPicker() {
      this.addressPicker = this.$createCascadePicker({
        title: '请选择地址',
        data: addressData,
        onSelect: this.selectAddressHandle
      });
    },
    showAddressPicker() {
      this.addressPicker.show();
    },
    selectAddressHandle(selectedVal, selectedIndex, selectedText) {
      this.model.address = selectedText.join(' ');
      this.model.addrProvince = selectedVal[0] || '';
      this.model.addrCity = selectedVal[1] || '';
      this.model.addrArea = selectedVal[2] || '';
    },
    // 编辑进入
    toEdit(contactId) {
      custContactsDetail(contactId).then(res => {
        if (res.flag) {
          this.model = Object.assign(this.model, res.data);
          this.mobile.addrStreet = res.data.addrStreet || '';
          this.mobile.hobby = res.data.hobby || '';
          this.mobile.remark = res.data.remark || '';
          // 若此数据 contractRelationFlag = 1；为指定联系人则禁用
          if (this.model.contractRelationFlag === 1) {
            this.fields[1].props.disabled = true;
            this.fields[3].props.disabled = true;
            this.fields[7].props.disabled = true;
            this.fields[10].props.disabled = true;
          }
          // 关于地址
          this.model.address = `${res.data.province ??= ''} ${res.data.city ??= ''} ${res.data.area ??= ''}`;
          // 关于图片
          res.data.busCardAfileUrl !== null && this.model.busCardAId.push({fileId: res.data.busCardAfileId, fileUrl: res.data.busCardAfileUrl });
          res.data.busCardBfileUrl !== null && this.model.busCardBId.push({fileId: res.data.busCardBfileId, fileUrl: res.data.busCardBfileUrl });
          let fileDetails = [...this.model.busCardAId, ...this.model.busCardBId];
          fileDetails.forEach((item, index) => {
            this.$nextTick(() => {
              let imgObj = document.getElementsByClassName('cube-upload-file-state');
              let textObj = document.getElementsByClassName('cube-upload-file-progress');
              imgObj[index].style.backgroundImage = `url(${item.fileUrl})`;// 设置背景图的的地址
              imgObj[index].style.backgroundRepeat = 'no-repeat';// 设置背景不平铺
              imgObj[index].style.backgroundPosition = 'center';// 设置背景图的位置
              imgObj[index].style.backgroundSize = 'cover';// 设置背景图像的尺寸
              imgObj[index].style.opacity = 1;
              textObj[index].style.display = 'none';
            });
          });
        } else {
          this.$showToast(res.errorMsg);
        }
      });
    },
    // 跳转新增联系人
    toQyWeChat() {
      let {custId, custCn} = this.$route.query;
      this.model.custCn = custCn;
      this.model.custId = custId;
      this.selectCustCn = false;
    },
    // 指定联系人提示
    appointToast(typeName) {
      if (this.model.contractRelationFlag === 1) this.$showToast(`${this.model.name}为合同指定联系人，${typeName}不支持修改`);
    }
  }
};
</script>

<style lang="scss" scoped src="@/assets/css/components/btn-bottom.scss"></style>
<style lang="scss" scoped>
.form-custom {
  height: 100%;
  text-align: left;
  .form-wrapper {
    height: 100%;
  }
  .form-content {
    height: calc(100% - 60px);
    overflow: scroll;
  }
  .after-space {
    height: 10px;
    background: $color-F7;
  }
  .field-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .cube-inputs{
      flex: 1;
    }
    .arrows {
      color: $color-CCC;
      font-size: 11px;
    }
    .arrow-right {
      align-self: flex-end;
    }
    .button {
      flex: 0 0 70px;
      color: $color-theme;
      font-weight: 400;
      font-size: 14px;
      text-align: right;
    }
  }
  .save-btn {
    padding: 8px 15px;
  }
}
.no-border::after {
  border-color:transparent;
}
.color-ccc {
  color: $color-CCC;
}
.color-333 {
  color: $color-333;
}
</style>
